var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex material-form flex-wrap flex-spacing",
      class: { "preloader preloader-cover": _vm.processing },
    },
    [
      _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
        _c(
          "div",
          { staticClass: "input" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.name,
                  expression: "form.name",
                },
              ],
              class: { error: _vm.$v.form.name.$error },
              attrs: {
                type: "text",
                name: _vm.inputId("name"),
                id: _vm.inputId("name"),
                "placeholder-sticky": "",
              },
              domProps: { value: _vm.form.name },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "name", $event.target.value)
                  },
                  function ($event) {
                    return _vm.$v.form.name.$touch()
                  },
                ],
              },
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: _vm.inputId("name") } }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t.get("auth.first-name")) +
                  "\n        "
              ),
              _c("strong", { staticClass: "accent" }, [_vm._v("*")]),
            ]),
            _vm._v(" "),
            _c("v-input-error", { attrs: { validation: _vm.$v.form.name } }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
        _c(
          "div",
          { staticClass: "input" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.surname,
                  expression: "form.surname",
                },
              ],
              class: { error: _vm.$v.form.surname.$error },
              attrs: {
                type: "text",
                name: _vm.inputId("surname"),
                id: _vm.inputId("surname"),
                "placeholder-sticky": "",
              },
              domProps: { value: _vm.form.surname },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "surname", $event.target.value)
                  },
                  function ($event) {
                    return _vm.$v.form.surname.$touch()
                  },
                ],
              },
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: _vm.inputId("surname") } }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t.get("auth.last-name")) +
                  "\n        "
              ),
              _c("strong", { staticClass: "accent" }, [_vm._v("*")]),
            ]),
            _vm._v(" "),
            _c("v-input-error", { attrs: { validation: _vm.$v.form.surname } }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "w-full lg:w-6/12 columns" },
        [
          _c("birthday-input", {
            class: { error: _vm.$v.form.birthday.$error },
            on: {
              input: function ($event) {
                return _vm.$v.form.birthday.$touch()
              },
            },
            model: {
              value: _vm.form.birthday,
              callback: function ($$v) {
                _vm.$set(_vm.form, "birthday", $$v)
              },
              expression: "form.birthday",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
        _c(
          "div",
          { staticClass: "input" },
          [
            _c("v-select", {
              class: { error: _vm.$v.form.gender.$error },
              attrs: {
                id: "gender",
                options: [
                  { label: _vm.$t.get("common.male"), value: "male" },
                  { label: _vm.$t.get("common.female"), value: "female" },
                ],
              },
              on: {
                input: function ($event) {
                  return _vm.$v.form.gender.$touch()
                },
              },
              model: {
                value: _vm.form.gender,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "gender", $$v)
                },
                expression: "form.gender",
              },
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "gender" } }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t.get("auth.gender")) + "\n        "
              ),
              _c("strong", { staticClass: "accent" }, [_vm._v("*")]),
            ]),
            _vm._v(" "),
            _c("v-input-error", { attrs: { validation: _vm.$v.form.gender } }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "w-full lg:w-full columns" },
        [
          _c("v-input-error", {
            staticClass: "static",
            attrs: { validation: _vm.$v.form.birthday },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
        _c(
          "div",
          { staticClass: "input" },
          [
            _c(
              "v-select",
              {
                class: { error: _vm.$v.form.country.$error },
                attrs: { id: "country", "enable-search": true },
                model: {
                  value: _vm.form.country,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "country", $$v)
                  },
                  expression: "form.country",
                },
              },
              _vm._l(_vm.countries, function (country, index) {
                return _c("v-option", {
                  key: index,
                  attrs: {
                    value: country.code,
                    label: _vm.$t.get(country.translation_key),
                  },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("label", { attrs: { for: "country" } }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t.get("auth.country")) + "\n        "
              ),
              _c("strong", { staticClass: "accent" }, [_vm._v("*")]),
            ]),
            _vm._v(" "),
            _c("v-input-error", { attrs: { validation: _vm.$v.form.country } }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
        _c("div", { staticClass: "input" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.city,
                expression: "form.city",
              },
            ],
            class: { error: _vm.$v.form.city.$error },
            attrs: {
              type: "text",
              name: _vm.inputId("city"),
              id: _vm.inputId("city"),
              "placeholder-sticky": "",
            },
            domProps: { value: _vm.form.city },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "city", $event.target.value)
                },
                function ($event) {
                  return _vm.$v.form.city.$touch()
                },
              ],
            },
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: _vm.inputId("city") } }, [
            _vm._v(_vm._s(_vm.$t.get("auth.city"))),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
        _c(
          "div",
          { staticClass: "input" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.email,
                  expression: "form.email",
                },
              ],
              class: { error: _vm.$v.form.email.$error },
              attrs: {
                type: "text",
                name: _vm.inputId("email"),
                id: _vm.inputId("email"),
                "placeholder-sticky": "",
              },
              domProps: { value: _vm.form.email },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "email", $event.target.value)
                  },
                  function ($event) {
                    return _vm.$v.form.email.$touch()
                  },
                ],
              },
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: _vm.inputId("email") } }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t.get("auth.email")) + "\n        "
              ),
              _c("strong", { staticClass: "accent" }, [_vm._v("*")]),
            ]),
            _vm._v(" "),
            _c("v-input-error", { attrs: { validation: _vm.$v.form.email } }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-full lg:w-3/12 columns" }, [
        _c(
          "div",
          { staticClass: "input" },
          [
            _c("v-select", {
              attrs: {
                id: "two-factor-auth-modal-phone-country",
                "enable-search": true,
                options: _vm.countryCodes,
                "label-key": "label",
                "value-key": "value",
              },
              on: {
                input: function ($event) {
                  return _vm.$v.form.countryCode.$touch()
                },
              },
              model: {
                value: _vm.countryCode,
                callback: function ($$v) {
                  _vm.countryCode = $$v
                },
                expression: "countryCode",
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              { attrs: { for: "two-factor-auth-modal-phone-country" } },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t.get("auth.phone-verification-country-code")) +
                    "\n        "
                ),
                _c("strong", { staticClass: "accent" }, [_vm._v("*")]),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-full lg:w-3/12 columns" }, [
        _c(
          "div",
          { staticClass: "input" },
          [
            _c("input", {
              directives: [
                {
                  name: "cleave",
                  rawName: "v-cleave.config",
                  value: _vm.phoneMask,
                  expression: "phoneMask",
                  modifiers: { config: true },
                },
              ],
              class: { error: _vm.$v.form.phone.$error },
              attrs: {
                type: "text",
                name: "phone",
                id: "phone",
                disabled: _vm.phoneCode == null,
              },
              on: {
                input: function ($event) {
                  return _vm.$v.form.phone.$touch()
                },
              },
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "phone" } }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t.get("auth.phone")) + "\n        "
              ),
              _c("strong", { staticClass: "accent" }, [_vm._v("*")]),
            ]),
            _vm._v(" "),
            _c("v-input-error", { attrs: { validation: _vm.$v.form.phone } }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
        _c(
          "div",
          { staticClass: "input" },
          [
            _c(
              "v-select",
              {
                class: { error: _vm.$v.form.tdCustomerPlanId.$error },
                attrs: {
                  id: "customer_plan_id",
                  "enable-search": true,
                  disabled: _vm.isReadOnly("tdCustomerPlanId"),
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.form.tdCustomerPlanId.$touch()
                  },
                },
                model: {
                  value: _vm.form.tdCustomerPlanId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "tdCustomerPlanId", $$v)
                  },
                  expression: "form.tdCustomerPlanId",
                },
              },
              _vm._l(_vm.customerPlans, function (plan, index) {
                return _c("v-option", {
                  key: index,
                  attrs: { value: plan.customer_plan_id, label: plan.name },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("label", { attrs: { for: "customer_plan_id" } }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t.get("product.customer-plan")) +
                  "\n        "
              ),
              _c("strong", { staticClass: "accent" }, [_vm._v("*")]),
            ]),
            _vm._v(" "),
            _c("v-input-error", {
              attrs: { validation: _vm.$v.form.tdCustomerPlanId },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-full lg:w-full columns required-info" }, [
        _c("span", [
          _c("strong", { staticClass: "accent" }, [_vm._v("*")]),
          _vm._v(
            "\n      " + _vm._s(_vm.$t.get("auth.required-fields")) + "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-full flex flex-wrap flex-spacing buttons" }, [
        _c("div", { staticClass: "w-full lg:w-6/12 columns" }),
        _vm._v(" "),
        _c("div", { staticClass: "w-full lg:w-6/12 columns text-right" }, [
          _c(
            "button",
            {
              staticClass: "button accent",
              attrs: { disabled: _vm.processing || _vm.$v.form.$invalid },
              on: { click: _vm.addCustomer },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t.get("product.create-patient")) +
                  "\n      "
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }