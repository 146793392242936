var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.token || _vm.resendForm
    ? _c("div", [
        _c("p", { staticClass: "description" }, [
          _vm._v(
            _vm._s(_vm.$t.get("auth.please-enter-your-email-address-below"))
          ),
        ]),
        _vm._v(" "),
        _c("form", [
          _c("div", { staticClass: "flex flex-spacing flex-wrap" }, [
            _c("div", { staticClass: "w-full lg:w-full columns" }, [
              _c("div", { staticClass: "input" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.inputs.email,
                      expression: "inputs.email",
                    },
                  ],
                  attrs: {
                    type: "email",
                    name: "email",
                    placeholder: _vm.$t.get("auth.email"),
                  },
                  domProps: { value: _vm.inputs.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.inputs, "email", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "label",
                  { staticStyle: { display: "none" }, attrs: { for: "email" } },
                  [_vm._v(_vm._s(_vm.$t.get("auth.email")))]
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "form-error" }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-full columns submit" }, [
              _c("input", {
                staticClass: "button accent",
                attrs: { type: "submit", disabled: _vm.state == "sending" },
                domProps: { value: _vm.$t.get("auth.reset-password") },
                on: { click: _vm.resetPassword },
              }),
              _vm._v(" "),
              _c("p", {
                staticClass: "note",
                staticStyle: { "margin-top": "20px" },
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t.get(
                      "auth.if-you-have-any-problem-please-contact-us-info-trustedoctor-com"
                    )
                  ),
                },
              }),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "form-error" }),
          ]),
        ]),
      ])
    : _c("div", [
        _c("p", { staticClass: "description" }, [
          _vm._v(_vm._s(_vm.$t.get("auth.please-enter-new-password"))),
        ]),
        _vm._v(" "),
        _c("form", { staticClass: "flex flex-col" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.token,
                expression: "token",
              },
            ],
            attrs: { type: "hidden" },
            domProps: { value: _vm.token },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.token = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "w-full" }, [
            _c("div", { staticClass: "input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputs.password,
                    expression: "inputs.password",
                  },
                ],
                attrs: {
                  type: "password",
                  id: "password",
                  name: "password",
                  placeholder: _vm.$t.get("auth.new-password"),
                },
                domProps: { value: _vm.inputs.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.inputs, "password", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "password" } }, [
                _vm._v(_vm._s(_vm.$t.get("auth.new-password"))),
              ]),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "form-error" }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-full" }, [
            _c("div", { staticClass: "input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputs.password_confirmation,
                    expression: "inputs.password_confirmation",
                  },
                ],
                attrs: {
                  type: "password",
                  id: "confirm-password",
                  name: "confirm-password",
                  placeholder: _vm.$t.get("auth.confirm-password"),
                },
                domProps: { value: _vm.inputs.password_confirmation },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.inputs,
                      "password_confirmation",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "confirm-password" } }, [
                _vm._v(_vm._s(_vm.$t.get("auth.confirm-password"))),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "form-error" }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-full submit" }, [
            _c("input", {
              staticClass: "button accent",
              attrs: { type: "submit", disabled: _vm.state == "sending" },
              domProps: { value: _vm.$t.get("auth.reset-password") },
              on: { click: _vm.setNewPassword },
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "inline",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t.get(
                    "auth.if-you-have-any-problem-please-contact-us-info-trustedoctor-com"
                  )
                ),
              },
            }),
          ]),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }