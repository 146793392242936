//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Constants } from "../../models/Constants"
import TermPicker from "../../components/appointment/TermPicker.vue"
import ObjectStorageUploader from "../../components/cloud-manager/ObjectStorageUploader.vue"
import Modal from "../../plugins/modal/modal.js"
import store from "../../stores/store"
import { createNamespacedHelpers } from "vuex"
import Customize from "../../plugins/Customize"
import VueContextMenu from "../../plugins/VueContextMenu.vue"
import { Participant } from "twilio-video"

const { mapGetters } = createNamespacedHelpers("appointment")
const { mapGetters: mapThreadGetters } = createNamespacedHelpers("thread")

export default {
  store,
  mixins: [Customize()],
  props: {
    disabled: { default: false },
    disabledText: { default: "" },
  },
  components: {
    "object-storage-uploader": ObjectStorageUploader,
    VueContextMenu,
  },
  data() {
    return {
      message: "",
      sendingMessage: false,
      currentThread: null,
      processing: false,
      uploaderProcessing: false,
      uploadedFiles: [],
      constants: Constants,
      scrollPosition: 0,
      isScrolledDown: true,
      groupedAvatarsInThreadQuantity: 3,
    }
  },
  mounted() {},
  computed: {
    appointment() {
      return this.$store.state.appointment
    },
    ...mapGetters(["getMe"]),
    ...mapThreadGetters(["isThreadClosed"]),
    me() {
      return this.getMe(this.user.user_id)
    },
    messages() {
      if (this.thread === null || this.thread.hasOwnProperty("messages") === false) {
        return []
      }
      return this.thread.messages
    },
    storeProcessing() {
      return this.$store.state.thread.processing
    },
    thread() {
      if (this.currentThread === null) {
        return null
      }
      return this.$store.state.thread.threads[this.currentThread.thread_id] || null
    },
    threads() {
      return this.$store.state.thread.threads
    },
    hasThreads() {
      return Object.keys(this.threads).length > 0
    },
    appointmentId() {
      return this.$store.state.appointment.appointment_id
    },
    otherUnreaded() {
      return Object.values(this.threads)
        .filter((thread) => thread.thread_id !== this.currentThread.thread_id)
        .reduce((count, thread) => count + (thread.unread_count || 0), 0)
    },
    quillOptions() {
      return {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["attach"],
          ],
        },
        objectId: this.appointmentId,
      }
    },
    user() {
      return this.$store.state.user
    },
  },
  watch: {
    messages() {
      setTimeout(() => {
        if (this.$refs.scrollbar !== void 0 && this.isScrolledDown) {
          this.scrollMessagesBottom(false)
        }
      }, 300)
    },
  },
  sockets: {
    threads(payload) {
      this.$nextTick(() => {
        if (this.hasThreads && this.$refs.scrollbar !== undefined && this.currentThread !== null) {
          this.fetchThread(this.currentThread.thread_id, false)
        }
      })
    },
  },
  methods: {
    quillReady() {
      this.$el
        .querySelector(".quill-editor .ql-toolbar .ql-attach")
        .addEventListener("click", this.attachFiles.bind(this))
    },
    attachFiles() {
      this.$refs.uploader.$refs.fileInput.click()
    },
    scrollPositionChanged(position) {
      this.scrollPosition = position

      this.isScrolledDown =
        this.scrollPosition ===
        this.$refs.scrollbar.$el.scrollHeight - this.$refs.scrollbar.$el.clientHeight
    },
    scrollMessagesBottom(markThreadAsRead) {
      this.$refs.scrollbar.$el.scrollTop =
        this.$refs.scrollbar.$el.scrollHeight - this.$refs.scrollbar.$el.clientHeight

      if (markThreadAsRead) {
        this.$store.dispatch("thread/markThreadAsRead", { thread_id: thread_id })
      }
    },
    backToMessages() {
      this.currentThread = null
    },
    fetchThread(thread, mark_as_read = true) {
      if (thread.is_available) {
        this.currentThread = thread
        this.processing = true
        if (mark_as_read) {
          this.$store.dispatch("thread/markThreadAsRead", { thread_id: thread.thread_id })
        }
        this.$store.dispatch("thread/fetch", { thread_id: thread.thread_id }).then(() => {
          this.processing = false
          this.scrollMessagesBottom(false)
        })
      }
    },
    joinToThread(thread) {
      if (thread.can_join) {
        this.$store.dispatch("thread/join", {
          thread_id: thread.thread_id,
          appointment_id: this.appointment.appointment_id,
        })
      }
    },
    openTermPicker() {
      Modal.create(this)
        .title(this.$t.get("appointment.create-new-available-term"))
        .component(TermPicker)
        .show()
    },
    openCreateTerm() {
      Modal.create(this)
        .title(this.$t.get("appointment.create-new-thread"))
        .component(() => import("./MessagesNewThread.vue"))
        .classes(["modal-create-thread"])
        .events({
          success: () => {
            this.$store.dispatch("thread/fetchThreads", {
              object_id: this.appointment.appointment_id,
              object_type: Constants.Thread.TYPE_APPOINTMENT,
            })
          },
        })
        .show()
    },
    sendMessage() {
      this.processing = true
      this.$store
        .dispatch("thread/sendMessage", {
          thread_id: this.thread.thread_id,
          message: this.message,
          files: this.uploadedFiles,
        })
        .then(
          () => {
            this.message = ""
            this.processing = false
            this.$store.dispatch("thread/markThreadAsRead", { thread_id: this.thread.thread_id })
            this.uploadedFiles = []
            this.$refs.uploader.clearUploaded()
            this.scrollMessagesBottom(false)
          },
          (error) => {
            this.$bridge.emit("addToast", error.response.data.message, "error")
            this.processing = false
          }
        )
    },
    castClass(type) {
      switch (type) {
        case 1:
          return "type-request"
          break
        case 2:
          return "type-second-opinion"
          break
        default:
          return ""
      }
    },
    getRecipientAvatar(thread) {
      if (
        thread.sender_id === this.user.user_id &&
        thread.thread_id === this.appointment.thread_id
      ) {
        return this.appointment.group.avatar_url
      } else {
        return thread.recipients_avatar || this.appointment.group.avatar_url
      }
    },
    getRecipientLabel(thread) {
      if (
        thread.sender_id === this.user.user_id &&
        thread.thread_id === this.appointment.thread_id
      ) {
        return this.appointment.group.name
      } else {
        return thread.subject || thread.recipients_label
      }
    },
    handleCloseOrReopenThread(thread, action) {
      if (thread.can_manage) {
        this.$store.dispatch(`thread/${action}`, {
          thread_id: thread.thread_id,
          appointment_id: this.appointment.appointment_id,
        })
      }
    },
    openRenameThreadModal(thread) {
      Modal.create(this)
        .component(() => import("./MessagesRenameThreadModal.vue"))
        .title(this.$t.get("thread.rename-thread"))
        .props({
          name: this.getRecipientLabel(thread),
        })
        .events({
          rename: (updatedName) => {
            this.renameThread(thread, updatedName)
          },
        })
        .closeable(true)
        .show()
    },
    renameThread(thread, name) {
      this.$store.dispatch("thread/rename", {
        thread_id: thread.thread_id,
        currentName: name,
        appointment_id: this.appointment.appointment_id,
      })
    },
    removeParticipant(thread_id, user_id) {
      this.$store
        .dispatch(`thread/removeParticipant`, {
          thread_id: thread_id,
          user_id: user_id,
        })
        .then(() => {
          this.$bridge.emit("addToast", this.$t.get("thread.participant-removed"), "success")
        })
        .catch((error) => {
          this.$bridge.emit("addToast", error.response.data.message, "error")
        })
    },
    addParticipants(thread_id, selectedParticipants) {
      const selectedParticipantsIds = selectedParticipants.map((participant) => participant.user_id)
      this.$store
        .dispatch(`thread/addParticipants`, {
          thread_id: thread_id,
          selectedParticipants: selectedParticipantsIds,
        })
        .then(() => {
          this.$store.dispatch("thread/fetchThreads", {
            object_id: this.appointment.appointment_id,
            object_type: Constants.Thread.TYPE_APPOINTMENT,
          })
          this.$bridge.emit("addToast", this.$t.get("thread.participant-added"), "success")
        })
        .catch((error) => {
          this.$bridge.emit("addToast", error.response.data.message, "error")
        })
    },
    openManageParticipantsModal(thread) {
      Modal.create(this)
        .component(() => import("./MessagesManageParticipantsModal.vue"))
        .title(this.$t.get("thread.manage-participants"))
        .props({
          users: thread.users,
          thread: thread,
        })
        .events({
          removeParticipant: (user_id) => {
            this.removeParticipant(thread.thread_id, user_id)
          },
          openAddParticipantModal: () => this.openAddParticipantModal(thread),
        })
        .classes(["modal-manage-thread-participants"])
        .closeable(true)
        .show()
    },
    openAddParticipantModal(thread) {
      Modal.create(this)
        .title(this.$t.get("thread.add-new-participants"))
        .component(() => import("./MessagesAddParticipantModal.vue"))
        .classes(["modal-add-participant"])
        .events({
          success: () => {
            this.$store.dispatch("thread/fetchThreads", {
              object_id: this.appointment.appointment_id,
              object_type: Constants.Thread.TYPE_APPOINTMENT,
            })
          },
          addSelected: (selectedParticipants) => {
            this.addParticipants(thread.thread_id, selectedParticipants)
          },
        })
        .show()
    },
  },
}
