//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    token: {
      default: null,
    },
  },
  data() {
    return {
      state: "init", // [init, sending, done]
      resendForm: false,
      inputs: {
        token: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
    }
  },
  mounted() {
    if (this.token !== "") this.inputs.token = this.token
  },
  methods: {
    showResend() {
      this.resendForm = true
    },
    resetPassword(e) {
      e.preventDefault()
      this.state = "sending"
      this.$http.post("/ajax/password/reset", this.inputs).then(
        (response) => {
          this.$bridge.emit("addToast", this.$t.get("auth.email-instructions-sent"), "success")
          this.state = "done"
          this.$emit("success")
        },
        (error) => {
          this.state = "done"
          this.$bridge.emit("addToast", error.response.data.message, "error")
        }
      )
    },
    setNewPassword(e) {
      e.preventDefault()
      this.state = "sending"
      this.inputs.token = this.token
      this.$http.post("/ajax/password/new", this.inputs).then(
        (response) => {
          this.state = "done"
          this.$bridge.emit("addToast", this.$t.get("auth.forgot-password-set"), "success")
          if (response.data.data.redirect_url) {
            window.location.href = response.data.data.redirect_url
          }
          this.$emit("success")
        },
        (error) => {
          this.state = "done"
          this.$bridge.emit("addToast", error.response.data.message, "error")
        }
      )
    },
  },
}
