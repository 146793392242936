var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "appointment-threads",
      class: { "preloader preloader-cover": _vm.storeProcessing },
    },
    [
      !_vm.storeProcessing
        ? _c("div", [
            _vm.currentThread === null
              ? _c("div", { staticClass: "contacts" }, [
                  _c("div", { staticClass: "section" }, [
                    _vm._v(_vm._s(_vm.$t.get("appointment.list-of-message"))),
                  ]),
                  _vm._v(" "),
                  _vm.hasThreads
                    ? _c(
                        "ul",
                        {
                          directives: [
                            {
                              name: "styled",
                              rawName: "v-styled:border-color:new-message",
                              value: _vm.palette.colors.primary.main,
                              expression: "palette.colors.primary.main",
                              arg: "border-color:new-message",
                            },
                          ],
                        },
                        [
                          _vm._l(_vm.threads, function (thread) {
                            return _c(
                              "li",
                              {
                                key: thread.thread_id,
                                class: {
                                  "new-message": thread.unread_count > 0,
                                  disabled: thread.is_available === false,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.fetchThread(thread)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "flex" }, [
                                  _c(
                                    "div",
                                    { staticClass: "w-full lg:w-9/12 flex" },
                                    [
                                      _c("div", { staticClass: "h-12 w-12" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "avatar relative",
                                            class: {
                                              "has-badge":
                                                thread.unread_count > 0,
                                            },
                                            style: {
                                              "background-image":
                                                "url('" +
                                                _vm.getRecipientAvatar(thread) +
                                                "')",
                                            },
                                            attrs: {
                                              badge: thread.unread_count,
                                            },
                                          },
                                          [
                                            thread.users.length > 2
                                              ? _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "styled",
                                                        rawName:
                                                          "v-styled:background.hover",
                                                        value:
                                                          _vm.palette.colors
                                                            .primary.main,
                                                        expression:
                                                          "palette.colors.primary.main",
                                                        arg: "background",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "participants",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "+" +
                                                          _vm._s(
                                                            thread.users
                                                              .length - 1
                                                          )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "tooltip__container",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "container__content",
                                                          },
                                                          [
                                                            _c(
                                                              "ul",
                                                              _vm._l(
                                                                thread.users,
                                                                function (
                                                                  user
                                                                ) {
                                                                  return _c(
                                                                    "li",
                                                                    {
                                                                      staticClass:
                                                                        "tooltip__participant",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "h-5 w-5 rounded-full bg-cover",
                                                                          style:
                                                                            {
                                                                              "background-image":
                                                                                "url('" +
                                                                                user.avatar_url +
                                                                                "')",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              user.display_name
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _c("div", {
                                                  staticClass:
                                                    "participants bg-cover",
                                                  style: {
                                                    "background-image":
                                                      "url('" +
                                                      _vm.me.user.avatar_url +
                                                      "')",
                                                  },
                                                }),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "info self-center" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "recipient" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRecipientLabel(thread)
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "flex items-center",
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "last-message",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    (
                                                      thread.last_message || ""
                                                    ).replace(
                                                      /<(?:.|\n)*?>/gm,
                                                      " "
                                                    )
                                                  ),
                                                },
                                              }),
                                              _vm._v(" "),
                                              thread.last_message
                                                ? _c("div", {
                                                    staticClass: "divider",
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "date" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$dateTimeFormat.formatTime(
                                                          thread.updated_at
                                                        )
                                                      ) +
                                                      ",\n                    " +
                                                      _vm._s(
                                                        _vm.$dateTimeFormat.formatDate(
                                                          thread.updated_at
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "w-full lg:w-3/12 actions" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                        },
                                        [
                                          thread.can_join === true ||
                                          thread.can_manage === true
                                            ? _c(
                                                "vue-context-menu",
                                                { staticClass: "right-top" },
                                                [
                                                  thread.can_join === true &&
                                                  thread.is_available === false
                                                    ? _c(
                                                        "li",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "styled",
                                                              rawName:
                                                                "v-styled:color.hover",
                                                              value:
                                                                _vm.palette
                                                                  .colors.hover
                                                                  .main,
                                                              expression:
                                                                "palette.colors.hover.main",
                                                              arg: "color",
                                                              modifiers: {
                                                                hover: true,
                                                              },
                                                            },
                                                          ],
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.joinToThread(
                                                                thread
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm.$t.get(
                                                                  "appointment.join-to-thread"
                                                                )
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  thread.can_manage
                                                    ? [
                                                        _c(
                                                          "li",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "styled",
                                                                rawName:
                                                                  "v-styled:color.hover",
                                                                value:
                                                                  _vm.palette
                                                                    .colors
                                                                    .hover.main,
                                                                expression:
                                                                  "palette.colors.hover.main",
                                                                arg: "color",
                                                                modifiers: {
                                                                  hover: true,
                                                                },
                                                              },
                                                            ],
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openAddParticipantModal(
                                                                  thread
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  _vm.$t.get(
                                                                    "thread.add-new-participant"
                                                                  )
                                                                ) +
                                                                "\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "li",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "styled",
                                                                rawName:
                                                                  "v-styled:color.hover",
                                                                value:
                                                                  _vm.palette
                                                                    .colors
                                                                    .hover.main,
                                                                expression:
                                                                  "palette.colors.hover.main",
                                                                arg: "color",
                                                                modifiers: {
                                                                  hover: true,
                                                                },
                                                              },
                                                            ],
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openManageParticipantsModal(
                                                                  thread
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  _vm.$t.get(
                                                                    "thread.manage-participants"
                                                                  )
                                                                ) +
                                                                "\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        thread.status === 1
                                                          ? _c(
                                                              "li",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "styled",
                                                                    rawName:
                                                                      "v-styled:color.hover",
                                                                    value:
                                                                      _vm
                                                                        .palette
                                                                        .colors
                                                                        .hover
                                                                        .main,
                                                                    expression:
                                                                      "palette.colors.hover.main",
                                                                    arg: "color",
                                                                    modifiers: {
                                                                      hover: true,
                                                                    },
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "close",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleCloseOrReopenThread(
                                                                        thread,
                                                                        "close"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.$t.get(
                                                                        "thread.close-thread"
                                                                      )
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "li",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "styled",
                                                                    rawName:
                                                                      "v-styled:color.hover",
                                                                    value:
                                                                      _vm
                                                                        .palette
                                                                        .colors
                                                                        .hover
                                                                        .main,
                                                                    expression:
                                                                      "palette.colors.hover.main",
                                                                    arg: "color",
                                                                    modifiers: {
                                                                      hover: true,
                                                                    },
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "reopen",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleCloseOrReopenThread(
                                                                        thread,
                                                                        "open"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.$t.get(
                                                                        "thread.reopen-thread"
                                                                      )
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ]
                                                            ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "li",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "styled",
                                                                rawName:
                                                                  "v-styled:color.hover",
                                                                value:
                                                                  _vm.palette
                                                                    .colors
                                                                    .hover.main,
                                                                expression:
                                                                  "palette.colors.hover.main",
                                                                arg: "color",
                                                                modifiers: {
                                                                  hover: true,
                                                                },
                                                              },
                                                            ],
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openRenameThreadModal(
                                                                  thread
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  _vm.$t.get(
                                                                    "thread.rename-thread"
                                                                  )
                                                                ) +
                                                                "\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "status" }, [
                                        _vm.isThreadClosed(thread)
                                          ? _c(
                                              "span",
                                              { staticClass: "status__closed" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t.get(
                                                      "thread.thread-closed"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              { staticClass: "status__open" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t.get(
                                                      "thread.thread-open"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _vm.appointment.is_user_group_member
                            ? _c(
                                "li",
                                {
                                  staticClass: "create",
                                  on: { click: _vm.openCreateTerm },
                                },
                                [
                                  _c("i", { staticClass: "ict-plus-round" }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t.get(
                                          "appointment.create-new-thread"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.currentThread !== null
              ? _c("div", { staticClass: "thread" }, [
                  _c("div", { staticClass: "with" }, [
                    _c("div", { staticClass: "flex items-center" }, [
                      _c("div", { staticClass: "w-3/12 lg:w-1/12" }, [
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "styled",
                                rawName: "v-styled:color",
                                value: _vm.palette.colors.secondary.main,
                                expression: "palette.colors.secondary.main",
                                arg: "color",
                              },
                              {
                                name: "styled",
                                rawName: "v-styled:background",
                                value: _vm.palette.colors.primary.alt,
                                expression: "palette.colors.primary.alt",
                                arg: "background",
                              },
                              {
                                name: "styled",
                                rawName: "v-styled:border-color",
                                value: _vm.palette.colors.primary.main,
                                expression: "palette.colors.primary.main",
                                arg: "border-color",
                              },
                              {
                                name: "styled",
                                rawName: "v-styled:color:ict-back-arrow",
                                value: _vm.palette.colors.primary.main,
                                expression: "palette.colors.primary.main",
                                arg: "color:ict-back-arrow",
                              },
                              {
                                name: "styled",
                                rawName: "v-styled:color:ict-back-arrow.hover",
                                value: _vm.palette.colors.hover.alt,
                                expression: "palette.colors.hover.alt",
                                arg: "color:ict-back-arrow",
                                modifiers: { hover: true },
                              },
                              {
                                name: "styled",
                                rawName: "v-styled:background.hover",
                                value: _vm.palette.colors.hover.main,
                                expression: "palette.colors.hover.main",
                                arg: "background",
                                modifiers: { hover: true },
                              },
                              {
                                name: "styled",
                                rawName: "v-styled:color.hover",
                                value: _vm.palette.colors.hover.alt,
                                expression: "palette.colors.hover.alt",
                                arg: "color",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "button with-icon",
                            class: { "has-badge": _vm.otherUnreaded > 0 },
                            attrs: { badge: _vm.otherUnreaded },
                            on: { click: _vm.backToMessages },
                          },
                          [_c("i", { staticClass: "ict-back-arrow" })]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "w-9/12 lg:w-11/12 user" },
                        [
                          _c(
                            "div",
                            { staticClass: "flex -space-x-2" },
                            _vm._l(
                              _vm.currentThread.users.slice(
                                0,
                                _vm.groupedAvatarsInThreadQuantity
                              ),
                              function (user, index) {
                                return _c("div", {
                                  directives: [
                                    { name: "tooltip", rawName: "v-tooltip" },
                                  ],
                                  key: index,
                                  staticClass: "user__avatar",
                                  style: {
                                    "background-image":
                                      "url('" + user.avatar_url + "')",
                                  },
                                  attrs: { alt: user.display_name },
                                })
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _vm.currentThread.users.length >
                          _vm.groupedAvatarsInThreadQuantity
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "styled",
                                      rawName: "v-styled:background.hover",
                                      value: _vm.palette.colors.primary.main,
                                      expression: "palette.colors.primary.main",
                                      arg: "background",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  staticClass: "participants",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "participants__other" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "+" +
                                            _vm._s(
                                              _vm.currentThread.users.length -
                                                _vm.groupedAvatarsInThreadQuantity
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "tooltip__container" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "container__content" },
                                        [
                                          _c(
                                            "ul",
                                            { staticClass: "space-y-2" },
                                            _vm._l(
                                              _vm.currentThread.users.slice(
                                                _vm.groupedAvatarsInThreadQuantity
                                              ),
                                              function (user) {
                                                return _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "tooltip__participant",
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "h-5 w-5 rounded-full bg-cover",
                                                      style: {
                                                        "background-image":
                                                          "url('" +
                                                          user.avatar_url +
                                                          "')",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          user.display_name
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              _vm._s(_vm.getRecipientLabel(_vm.currentThread))
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.currentThread.can_manage === true ||
                          _vm.currentThread.can_join === true
                            ? _c(
                                "vue-context-menu",
                                { staticClass: "right-top" },
                                [
                                  _vm.currentThread.can_join === true &&
                                  _vm.currentThread.is_available === false
                                    ? _c(
                                        "li",
                                        {
                                          directives: [
                                            {
                                              name: "styled",
                                              rawName: "v-styled:color.hover",
                                              value:
                                                _vm.palette.colors.hover.main,
                                              expression:
                                                "palette.colors.hover.main",
                                              arg: "color",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.joinToThread(
                                                _vm.currentThread
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t.get(
                                                  "appointment.join-to-thread"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.currentThread.can_manage
                                    ? [
                                        _c(
                                          "li",
                                          {
                                            directives: [
                                              {
                                                name: "styled",
                                                rawName: "v-styled:color.hover",
                                                value:
                                                  _vm.palette.colors.hover.main,
                                                expression:
                                                  "palette.colors.hover.main",
                                                arg: "color",
                                                modifiers: { hover: true },
                                              },
                                            ],
                                            on: {
                                              click: function ($event) {
                                                return _vm.openAddParticipantModal(
                                                  _vm.thread
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t.get(
                                                    "thread.add-new-participant"
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "li",
                                          {
                                            directives: [
                                              {
                                                name: "styled",
                                                rawName: "v-styled:color.hover",
                                                value:
                                                  _vm.palette.colors.hover.main,
                                                expression:
                                                  "palette.colors.hover.main",
                                                arg: "color",
                                                modifiers: { hover: true },
                                              },
                                            ],
                                            on: {
                                              click: function ($event) {
                                                return _vm.openManageParticipantsModal(
                                                  _vm.currentThread
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t.get(
                                                    "thread.manage-participants"
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.thread.status === 1
                                          ? _c(
                                              "li",
                                              {
                                                directives: [
                                                  {
                                                    name: "styled",
                                                    rawName:
                                                      "v-styled:color.hover",
                                                    value:
                                                      _vm.palette.colors.hover
                                                        .main,
                                                    expression:
                                                      "palette.colors.hover.main",
                                                    arg: "color",
                                                    modifiers: { hover: true },
                                                  },
                                                ],
                                                staticClass: "close",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleCloseOrReopenThread(
                                                      _vm.currentThread,
                                                      "close"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t.get(
                                                        "thread.close-thread"
                                                      )
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "li",
                                              {
                                                directives: [
                                                  {
                                                    name: "styled",
                                                    rawName:
                                                      "v-styled:color.hover",
                                                    value:
                                                      _vm.palette.colors.hover
                                                        .main,
                                                    expression:
                                                      "palette.colors.hover.main",
                                                    arg: "color",
                                                    modifiers: { hover: true },
                                                  },
                                                ],
                                                staticClass: "reopen",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleCloseOrReopenThread(
                                                      _vm.currentThread,
                                                      "open"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t.get(
                                                        "thread.reopen-thread"
                                                      )
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                        _vm._v(" "),
                                        _c(
                                          "li",
                                          {
                                            directives: [
                                              {
                                                name: "styled",
                                                rawName: "v-styled:color.hover",
                                                value:
                                                  _vm.palette.colors.hover.main,
                                                expression:
                                                  "palette.colors.hover.main",
                                                arg: "color",
                                                modifiers: { hover: true },
                                              },
                                            ],
                                            on: {
                                              click: function ($event) {
                                                return _vm.openRenameThreadModal(
                                                  _vm.currentThread
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t.get(
                                                    "thread.rename-thread"
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "messages-wrapper",
                      class: { "preloader preloader-cover": _vm.processing },
                    },
                    [
                      _c(
                        "v-scrollbar",
                        {
                          ref: "scrollbar",
                          staticClass: "messages-list",
                          on: { scroll: _vm.scrollPositionChanged },
                        },
                        [
                          _c(
                            "transition-group",
                            { attrs: { name: "list", tag: "div" } },
                            _vm._l(_vm.messages, function (message) {
                              return _c(
                                "div",
                                {
                                  key: message.message_id,
                                  staticClass: "messages",
                                  class: [
                                    _vm.castClass(message.type),
                                    {
                                      "is-me":
                                        message.sender_id === _vm.user.user_id,
                                    },
                                    {
                                      "is-other":
                                        message.sender_id !== _vm.user.user_id,
                                    },
                                    {
                                      "is-support":
                                        message.type ===
                                        _vm.constants.Message.TYPE_SUPPORT,
                                    },
                                  ],
                                },
                                [
                                  message.type ===
                                  _vm.constants.Message.TYPE_SUPPORT
                                    ? _c("div", { staticClass: "message" }, [
                                        _c("div", { staticClass: "box" }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$dateTimeFormat.formatDate(
                                                  message.created_at
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(message.message) +
                                              "\n                "
                                          ),
                                        ]),
                                      ])
                                    : [
                                        message.sender_id === _vm.user.user_id
                                          ? _c("div", {
                                              staticClass: "avatar",
                                              style: {
                                                backgroundImage:
                                                  "url(" +
                                                  message.sender.avatar_url +
                                                  ")",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "message" }, [
                                          _c("div", { staticClass: "detail" }, [
                                            _c(
                                              "span",
                                              { staticClass: "name" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      message.sender.name
                                                    ) +
                                                    "\n                      " +
                                                    _vm._s(
                                                      message.sender.surname
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "styled",
                                                    rawName: "v-styled:color",
                                                    value:
                                                      _vm.palette.colors.primary
                                                        .main,
                                                    expression:
                                                      "palette.colors.primary.main",
                                                    arg: "color",
                                                  },
                                                ],
                                                staticClass: "date",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$dateTimeFormat.formatTime(
                                                        message.created_at
                                                      )
                                                    ) +
                                                    ",\n                      " +
                                                    _vm._s(
                                                      _vm.$dateTimeFormat.formatDate(
                                                        message.created_at
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "box" }, [
                                            _c("div", {
                                              staticClass: "appoitment-content",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  message.message
                                                ),
                                              },
                                            }),
                                            _vm._v(" "),
                                            message.files !== null &&
                                            message.files.length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "appoitment-files",
                                                  },
                                                  _vm._l(
                                                    message.files,
                                                    function (file, key) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: key,
                                                          staticClass: "file",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: file.link,
                                                                target:
                                                                  "_blank",
                                                                rel: "noopener nofollow",
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "ict-download",
                                                              }),
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.name
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        message.sender_id !== _vm.user.user_id
                                          ? _c("div", {
                                              staticClass: "avatar",
                                              style: {
                                                backgroundImage:
                                                  "url(" +
                                                  message.sender.avatar_url +
                                                  ")",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                ],
                                2
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isScrolledDown === false,
                              expression: "isScrolledDown === false",
                            },
                            {
                              name: "styled",
                              rawName: "v-styled:background",
                              value: _vm.palette.colors.primary.main,
                              expression: "palette.colors.primary.main",
                              arg: "background",
                            },
                          ],
                          staticClass: "scroll-down",
                          class: { "has-badge": _vm.thread.unread_count > 0 },
                          attrs: { badge: _vm.thread.unread_count },
                          on: {
                            click: function ($event) {
                              return _vm.scrollMessagesBottom(true)
                            },
                          },
                        },
                        [_c("i", { staticClass: "ict-angle-down" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isThreadClosed(_vm.currentThread) === false &&
                  _vm.currentThread != null &&
                  _vm.disabled === false
                    ? _c(
                        "div",
                        {
                          staticClass: "send-message",
                          class: { "sending-message": _vm.processing },
                        },
                        [
                          _c("quill-editor", {
                            attrs: { options: _vm.quillOptions },
                            on: { ready: _vm.quillReady },
                            model: {
                              value: _vm.message,
                              callback: function ($$v) {
                                _vm.message = $$v
                              },
                              expression: "message",
                            },
                          }),
                          _vm._v(" "),
                          _c("object-storage-uploader", {
                            ref: "uploader",
                            attrs: {
                              "object-id": _vm.thread
                                ? _vm.thread.thread_id
                                : null,
                              "expanded-by-default": false,
                            },
                            on: {
                              processing: function ($event) {
                                _vm.uploaderProcessing = $event
                              },
                              finished: function ($event) {
                                _vm.uploadedFiles = $event
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "buttons" }, [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "styled",
                                    rawName: "v-styled:color",
                                    value: _vm.palette.colors.primary.alt,
                                    expression: "palette.colors.primary.alt",
                                    arg: "color",
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:background",
                                    value: _vm.palette.colors.primary.main,
                                    expression: "palette.colors.primary.main",
                                    arg: "background",
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:border-color",
                                    value: _vm.palette.colors.primary.main,
                                    expression: "palette.colors.primary.main",
                                    arg: "border-color",
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:background.hover",
                                    value: _vm.palette.colors.hover.alt,
                                    expression: "palette.colors.hover.alt",
                                    arg: "background",
                                    modifiers: { hover: true },
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:color.hover",
                                    value: _vm.palette.colors.hover.main,
                                    expression: "palette.colors.hover.main",
                                    arg: "color",
                                    modifiers: { hover: true },
                                  },
                                ],
                                staticClass: "button accent send",
                                attrs: {
                                  disabled:
                                    _vm.processing || _vm.uploaderProcessing,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.sendMessage()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t.get("appointment.send-message")
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.disabled
                    ? _c("div", { staticClass: "messages-disabled" }, [
                        _c("span", { staticClass: "label warning" }, [
                          _c("p", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(_vm.disabledText)),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isThreadClosed(_vm.currentThread)
                    ? _c(
                        "div",
                        { staticClass: "messages-disabled threads-list" },
                        [
                          _c("p", { staticClass: "text-center" }, [
                            _vm._v(
                              _vm._s(_vm.$t.get("appointment.thread-closed"))
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }