//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from "../../plugins/modal/modal"
import ResetPassword from "../../components/common/ResetPassword.vue"
import { validateUrl } from "../../../utils"
import Customize from "../../plugins/Customize"

export default {
  mixins: [Customize()],
  data() {
    return {
      processing: false,
      error: "",
      inputs: {
        email: "",
        password: "",
      },
      disableClose: false,
      message: null,
      redirectUrl: window.__REDIRECT_URL,
    }
  },
  bridges: {
    openSignIn(message, disableClose) {
      this.message = message
      this.disableClose = disableClose
      this.open()
    },
  },
  mounted() {},
  computed: {
    isUnsupportedBrowser() {
      return window.isUnsupportedBrowser()
    },
  },
  methods: {
    submit(e) {
      e.preventDefault()
      this.processing = true

      this.$http.post(window.__URL_LOGIN, this.inputs).then(
        (response) => {
          const redirectFn = (response) => {
            window.location.href = validateUrl(response.data.data.redirect_url)
              ? response.data.data.redirect_url
              : __APP_URL
          }
          if (response.data.data !== void 0 && response.data.data.twoFactorAuth === true) {
            this.$emit("cancel")
            const redirectAfter2FAFn = (response, code) => {
              const method_id = this.$store.state.twoFactor.lastUsedMethod
              this.$bridge.emit("addToast", this.$t.get("common.redirecting"), "success")
              this.$http
                .post(
                  window.__URL_LOGIN,
                  Object.assign(this.inputs, { twoFactorAuthCode: code, method_id: method_id })
                )
                .then(
                  (response) => {
                    redirectFn(response)
                  },
                  (error) => {
                    this.processing = false
                    this.$bridge.emit("addToast", error.response.data.message, "error")
                  }
                )
            }
            return this.$store.dispatch("twoFactor/perform", {
              callback: redirectAfter2FAFn.bind(this),
              sendMethods: response.data.data.twoFactorAuthSendMethods,
            })
          }
          redirectFn(response)
        },
        (error) => {
          this.processing = false
          this.$bridge.emit("addToast", error.response.data.message, "error")
        }
      )
    },
    forgotPassword() {
      this.$emit("cancel")
      Modal.create(this)
        .title(this.$t.get("auth.reset-password"))
        .component(ResetPassword)
        .props({
          token: window.__RESET_TOKEN,
        })
        .show()
    },
  },
}
